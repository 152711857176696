.header {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 15px 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    display: flex;
    align-items: center;

    .logoImage {
      height: 100px; /* Increase the height as needed */
      width: auto; /* Maintain aspect ratio */
    }

    .languageSelector {
      position: relative;

      .dropdownButton {
        background: none;
        border: none;
        color: white;
        font-size: 1rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        .flag {
          margin-right: 8px;
        }
      }

      .dropdownMenu {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #fff;
        color: #333;
        list-style: none;
        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        z-index: 1000;

        li {
          display: flex;
          align-items: center;
          padding: 10px;
          cursor: pointer;

          &:hover {
            background-color: #f0f0f0;
          }

          .flag {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .nav {
    display: none; /* Hide on mobile by default */

    @media (min-width: 768px) {
      display: block; /* Show on desktop */
      ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin: 0 15px; /* Space between links */

          a {
            text-decoration: none;
            color: #e0e2d5; /* Text color */
            transition: color 0.3s;
            text-transform: uppercase;
            font-size: 1.2rem;
            border: 2px solid transparent;

            &:hover {
              color: #007bff; /* Change color on hover */
              border-bottom: 2px solid #007bff;
            }
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    .nav ul li a {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 767px) {
    .hamburger-menu {
      display: block; /* Show hamburger menu on mobile */
    }
  }
}
